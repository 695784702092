import { capitalise, toCurrency, UseFormTools } from "@wisr/common";
import React from "react";

import { Application } from "../../../../application";
import {
  CREDIT_CARD_VALUE,
  debtTypeOptions,
} from "../../your-debts/debt-list/debt-list.constants";
import {
  appendIdentificationFields,
  debtReviewData,
  getHasChanges,
  getIdentityEditPath,
  getPaymentFrequencyString,
  getRentShared,
  getTimeSpentString,
  getTotalSpending,
} from "../review-your-details.utils";
import { LoanOffer } from "../../../../../types/loan-offer";
import {
  getIsPurposeSecurableByKey,
  getPurposeDescriptionByKey,
} from "../../../multi-page-application-form.utils";
import { Purpose } from "../../../../../types/loan-parameters";
import { livingSituationOptions } from "../../living-situation/living-situation.constants";
import {
  employmentIndustryOptions,
  employmentStatusOptions,
  occupationOptions,
} from "../../employment-and-income/employment-and-income.constants";
import { incomeSourceOptions } from "../../other-income-list/other-income-list.constants";

export interface ReviewField {
  label: string;
  value: string | JSX.Element;
  editable?: boolean;
  editPath?: string;
}

export const getRateAndRepayment = (loanTerm: string, loanOffer: LoanOffer) => {
  switch (loanTerm) {
    case "3":
      return {
        rate: loanOffer.Rates.Three,
        repayment: loanOffer.MonthlyRepaymentAmount.Three,
      };
    case "5":
      return {
        rate: loanOffer.Rates.Five,
        repayment: loanOffer.MonthlyRepaymentAmount.Five,
      };
    case "7":
      return {
        rate: loanOffer.Rates.Seven,
        repayment: loanOffer.MonthlyRepaymentAmount.Seven,
      };
    default:
      return {
        rate: undefined,
        repayment: undefined,
      };
  }
};

export const yourLoan = (
  formData: UseFormTools<Application>["formData"],
  loanPurposes: Purpose[],
  loanOffer?: LoanOffer
) => {
  const purposeDescription = getPurposeDescriptionByKey(
    formData.loanPurpose,
    loanPurposes
  );

  let base = [
    {
      label: "Loan purpose",
      value: purposeDescription,
    },
    {
      label: "Loan amount",
      value: toCurrency(parseInt(formData.loanAmount), false),
    },
    {
      label: "Term",
      value: `${formData.loanTerm} years`,
      editable: true,
      editPath: "loan-term",
    },
  ];

  if (getIsPurposeSecurableByKey(formData.loanPurpose, loanPurposes)) {
    base = [
      ...base,
      {
        label: "Have you already found the vehicle that you want?",
        value: formData.assetFound,
      },
    ];

    if (formData.assetFound === "Yes") {
      base = [
        ...base,
        {
          label: "Year of manufacture",
          value: formData.assetYear,
        },
        {
          label: "Type of vehicle seller",
          value: capitalise(formData.assetSeller),
        },
      ];
    } else if (formData.assetAgeEstimate) {
      base = [
        ...base,
        {
          label: "Ideal age of the vehicle you’re looking for",
          value: `${formData.assetAgeEstimate} years old`,
        },
      ];
    }
  }

  if (loanOffer) {
    const offerAmounts = getRateAndRepayment(formData.loanTerm, loanOffer);

    if (offerAmounts.rate && offerAmounts.repayment) {
      base.push(
        {
          label: "Estimated rate",
          value: `${offerAmounts.rate}% p.a.`,
        },
        {
          label: "Estimated repayments",
          value: `$${offerAmounts.repayment + loanOffer.MonthlyAccountFee}`,
        }
      );
    }
  }

  return base;
};

export const aboutYou = (formData: UseFormTools<Application>["formData"]) => [
  {
    label: "Name",
    value: `${formData.firstName} ${formData.lastName}`,
  },
  {
    label: "Date of birth",
    value: formData.dateOfBirth,
  },
  {
    label: "Mobile",
    value: formData.mobile,
  },
  {
    label: "Email",
    value: formData.email,
  },
  {
    label: "Relationship status",
    value: capitalise(formData.maritalStatus),
  },
  {
    label: "Financial dependants",
    value: formData.numberOfFinancialDependents,
    editable: true,
    editPath: "dependants",
  },
];

export const yourIDs = (formData: UseFormTools<Application>["formData"]) => {
  const base1 = [
    {
      label: "Type",
      value: formData.idenfiticationSource1,
      editable: true,
      editPath: getIdentityEditPath(formData.idenfiticationSource1),
    },
  ];

  const base2 = [
    {
      label: "Type",
      value: formData.idenfiticationSource2,
      editable: true,
      editPath: getIdentityEditPath(formData.idenfiticationSource2),
    },
  ];

  return [
    ...appendIdentificationFields({ baseField: base1, formData: formData }),
    ...appendIdentificationFields({ baseField: base2, formData: formData }),
  ];
};

export const livingSituation = (
  formData: UseFormTools<Application>["formData"],
  loanPurposes: Purpose[]
) => {
  const livingSituation =
    livingSituationOptions.find((livingSituationOption) => {
      return formData.livingSituationAtAddress === livingSituationOption.value;
    })?.label ?? formData.livingSituationAtAddress;

  let base = [
    {
      label: "Address",
      value: formData.address,
    },
    {
      label: "Current living situation",
      value: livingSituation,
      editable: true,
    },
    {
      label: "How long have you lived here?",
      value: getTimeSpentString({
        years: formData.yearsAtAddress,
        months: formData.monthsAtAddress,
      }),
    },
  ];

  if (formData.livingSituationAtAddress === "renter") {
    base = [
      ...base,
      {
        label: "Total rent",

        value: `${toCurrency(
          parseInt(formData.totalRentalAmount),
          false
        )} per ${getPaymentFrequencyString(formData.rentalAmountFrequency)}`,
        editable: true,
      },
      {
        label: "Is your rent shared with other people?",
        value: getRentShared(formData.rentShared[0]),
        editable: true,
      },
    ];
  }

  if (formData.livingSituationAtAddress === "living-with-parents") {
    base = [
      ...base,
      {
        label: "Parent name",
        value: `${formData.propertyOwnerManagerName}`,
        editable: true,
      },
      {
        label: "Parent phone number",
        value: formData.propertyOwnerManagerPhone,
        editable: true,
      },
    ];
  }

  if (formData.livingSituationAtAddress === "boarder") {
    base = [
      ...base,
      {
        label: "Property owner/manager name",
        value: `${formData.propertyOwnerManagerName}`,
        editable: true,
      },
      {
        label: "Property owner/manager phone number",
        value: formData.propertyOwnerManagerPhone,
        editable: true,
      },
    ];
  }

  if (formData.livingSituationAtAddress === "employer") {
    base = [
      ...base,
      {
        label: "Property owner/manager name",
        value: `${formData.propertyOwnerManagerName}`,
        editable: true,
      },
      {
        label: "Property owner/manager phone number",
        value: formData.propertyOwnerManagerPhone,
        editable: true,
      },
    ];
  }

  if (formData.livingSituationAtAddress === "buyer") {
    base = [
      ...base,
      {
        label: "Mortgage finance company name",
        value: `${formData.mortgageFinanceCompany}`,
        editable: true,
      },
      {
        label: "Mortgage balance",
        value: formData.mortgageBalance,
        editable: true,
      },
      {
        label: "Repayments",
        value: `${
          formData.mortgageRepaymentAmount
        } per ${getPaymentFrequencyString(
          formData.mortgageRepaymentFrequency
        )}`,
        editable: true,
      },
      {
        label: "Co-borrower",
        value: formData.hasCoBorrower,
        editable: true,
      },
    ];
  }

  if (getIsPurposeSecurableByKey(formData.loanPurpose, loanPurposes)) {
    base = [
      ...base,
      {
        label: "Do you own an investment property?",
        value: formData.ownsInvestmentProperty,
      },
    ];

    if (formData.ownsInvestmentProperty === "Yes") {
      base = [
        ...base,
        {
          label: "Is your investment property mortgaged?",
          value: formData.investmentPropertyMortgaged,
        },
      ];
    }
  }

  return base;
};

export const previousLivingSituation = (
  formData: UseFormTools<Application>["formData"]
) => {
  return [
    {
      label: "Address",
      value: formData.previousAddress,
    },
    {
      label: "How long were you at this address?",
      value: getTimeSpentString({
        years: formData.yearsAtPreviousAddress,
        months: formData.monthsAtPreviousAddress,
      }),
    },
  ];
};

export const yourEmploymentAndIncome = (
  formData: UseFormTools<Application>["formData"]
) => {
  const employmentStatus =
    employmentStatusOptions.find(
      (option) => option.value === formData.employmentStatus
    )?.label ?? formData.employmentStatus;
  const employmentOccupation =
    occupationOptions.find((option) => option.value === formData.occupation)
      ?.label ?? formData.occupation;
  const employmentIndustry =
    employmentIndustryOptions.find(
      (option) => option.value === formData.employmentIndustry
    )?.label ?? formData.employmentIndustry;

  const base = [
    {
      label: "Status",
      value: employmentStatus,
      editable: true,
      editPath: "employment-and-income",
    },
    {
      label: "Occupation",
      value: employmentOccupation,
      editable: true,
      editPath: "employment-and-income",
    },
    {
      label: "Time employed",
      value: getTimeSpentString({
        years: formData.yearsAtEmployer,
        months: formData.monthsAtEmployer,
      }),
      editable: true,
      editPath: "employment-and-income",
    },

    {
      label: "Employer industry",
      value: employmentIndustry,
      editable: true,
      editPath: "employment-and-income",
    },
    {
      label: "Employer name",
      value: formData.employerName,
      editable: true,
      editPath: "employment-and-income",
    },
    {
      label: "Employer contact number",
      value: formData.employmentContactNumber,
      editable: true,
      editPath: "employment-and-income",
    },
    {
      label: "Primary income",
      value: `${toCurrency(
        parseInt(formData.incomeAmount),
        false
      )} per ${getPaymentFrequencyString(formData.incomeFrequency)}`,
      editable: true,
      editPath: "employment-and-income",
    },
  ];

  if (+formData.partnerIncomeAmount > 0) {
    base.push({
      label: "Partner's income",
      value: toCurrency(parseInt(formData.partnerIncomeAmount), false),
      editable: true,
      editPath: "partners-income",
    });
  }

  return base;
};

export const yourPreviousEmploymentAndIncome = (
  formData: UseFormTools<Application>["formData"]
) => {
  const previousEmploymentStatus =
    employmentStatusOptions.find(
      (option) => option.value === formData.previousEmploymentStatus
    )?.label ?? formData.employmentStatus;
  const previousEmploymentOccupation =
    occupationOptions.find(
      (option) => option.value === formData.previousOccupation
    )?.label ?? formData.occupation;

  return [
    {
      label: "Status",
      value: previousEmploymentStatus,
    },
    {
      label: "Occupation",
      value: previousEmploymentOccupation,
    },
    {
      label: "Time employed",
      value: getTimeSpentString({
        years: formData.yearsAtPreviousEmployer,
        months: formData.monthsAtPreviousEmployer,
      }),
    },
    {
      label: "Employer name",
      value: formData.previousEmployerName,
    },
    {
      label: "Employer contact number",
      value: formData.previousEmploymentContactNumber,
    },
  ];
};

export const yourOtherIncome = (
  formData: UseFormTools<Application>["formData"]
) => {
  const base = [];
  const data: {
    [num: number]: { source: string; amount: string; frequency: string };
  } = {
    1: {
      source: formData.otherIncomeSource_1,
      amount: formData.otherIncomeAmount_1,
      frequency: formData.otherIncomeFrequency_1,
    },
    2: {
      source: formData.otherIncomeSource_2,
      amount: formData.otherIncomeAmount_2,
      frequency: formData.otherIncomeFrequency_2,
    },
    3: {
      source: formData.otherIncomeSource_3,
      amount: formData.otherIncomeAmount_3,
      frequency: formData.otherIncomeFrequency_3,
    },
  };
  for (let i = 1; i <= Object.keys(data).length; i++) {
    if (data[i].source !== "") {
      base.push(
        ...[
          {
            label: "Income source",
            value:
              incomeSourceOptions.find(
                (option) => option.value === data[i].source
              )?.label ?? data[i].source,
            editable: true,
            editPath: "other-income-list",
          },
          {
            label: "Income amount (before tax)",
            value: `${toCurrency(
              parseInt(data[i].amount),
              false
            )} per ${getPaymentFrequencyString(data[i].frequency)}`,
            editable: true,
            editPath: "other-income-list",
          },
        ]
      );
    }
  }

  if (base.length) {
    return [...base];
  }
  return [];
};

export const yourMonthlyExpenses = (
  formData: UseFormTools<Application>["formData"]
) => {
  const essentialExpenses = [
    formData.groceries,
    formData.clothing,
    formData.council_rates,
    formData.car_registration,
    formData.petrol,
    formData.car_home_insurance,
    formData.utilities,
    formData.communication,
    formData.transportation,
    formData.entertainment,
    formData.other_general_expenses,
  ];

  const additionalExpenses = [
    formData.health_insurance,
    formData.education,
    formData.seconds_motor_vehicle,
    formData.other_additional_expenses,
  ];

  return [
    {
      label: "Total essential living expenses",
      value: `$${getTotalSpending(essentialExpenses)}`,
      editable: true,
      editPath: "your-spending",
    },
    {
      label: "Total additional living expenses",
      value: `$${getTotalSpending(additionalExpenses)}`,
      editable: true,
      editPath: "additional-expenses",
    },
  ];
};

export const yourDebts = (formData: UseFormTools<Application>["formData"]) => {
  const data = debtReviewData(formData);
  const base = [];

  for (let i = 1; i <= Object.keys(data).length; i++) {
    if (data[i].type !== "") {
      const isCreditCard = data[i].type === CREDIT_CARD_VALUE;
      const debtTypeLabel = isCreditCard
        ? "Credit card"
        : debtTypeOptions.find((option) => option.value === data[i].type)
            ?.label ?? data[i].type;

      base.push(
        {
          label: "Type",
          value: debtTypeLabel,
          editable: true,
          editPath: "debt-list",
        },
        {
          label: "Company",
          value: data[i].company,
        }
      );
      if (isCreditCard) {
        base.push(
          {
            label: "Limit",
            value: `${toCurrency(parseInt(data[i].creditCardLimit), false)}`,
          },
          {
            label: "Do you intend to close this card?",
            value: data[i].closeCard[0] ? "Yes" : "No",
          }
        );
      } else {
        base.push({
          label: "Balance",
          value: `${toCurrency(parseInt(data[i].balance), false)}`,
        });
      }

      //if repayment amount exists push repayment per frequency
      if (data[i].repaymentAmount && data[i].repaymentFrequency) {
        base.push({
          label: "Repayments",
          value: `${toCurrency(
            parseInt(data[i].repaymentAmount),
            false
          )} per ${getPaymentFrequencyString(data[i].repaymentFrequency)}`,
        });
      }

      if (data[i].jointLoan.length) {
        base.push({
          label: "Is this a joint loan",
          value: "Yes",
        });
      }
      if (data[i].investment.length) {
        base.push({
          label: "Is this an investment property",
          value: "Yes",
        });
      }
      if (data[i].consolidate.length) {
        base.push({
          label: "Interested in consolidating this debt",
          value: "Yes",
        });
      }
    }
  }

  return base;
};

export const declarationOfChanges = (
  formData: UseFormTools<Application>["formData"]
) => {
  const { hasChanges, labelArr } = getHasChanges(
    formData.significantPlannedChangeTypes
  );
  const base: {
    label: string;
    value: string | JSX.Element;
    editable: boolean;
    editPath: string;
  }[] = [
    {
      label:
        "Do you expect changes to your income or expenses that may impact your ability to repay the loan?",
      value: hasChanges,
      editable: true,
      editPath: "significant-changes",
    },
  ];

  if (labelArr.length >= 1) {
    base.push({
      label: "What type of changes do you expect?",
      value: (
        <ul>
          {labelArr.map((label, i) => (
            <li key={i}>{label}</li>
          ))}
        </ul>
      ),
      editable: true,
      editPath: "type-of-changes",
    });
  }
  return base;
};

export const getReviewFields = (
  formData: UseFormTools<Application>["formData"],
  loanPurposes: Purpose[],
  loanOffer?: LoanOffer
) => ({
  "Your Loan": yourLoan(formData, loanPurposes, loanOffer),

  "About you": aboutYou(formData),

  "Your IDs": yourIDs(formData),

  "Your living situation": livingSituation(formData, loanPurposes).map(
    (item) => ({
      ...item,
      editPath: "living-situation",
    })
  ),

  ...(formData.previousAddress && {
    "Your previous living situation": previousLivingSituation(formData),
  }),

  "Your employment & income": yourEmploymentAndIncome(formData),

  ...(+formData.yearsAtEmployer === 0 &&
    +formData.monthsAtEmployer < 6 && {
      "Your previous employment & income": yourPreviousEmploymentAndIncome(
        formData
      ).map((item) => ({
        ...item,
        editable: true,
        editPath: "previous-employment-and-income",
      })),
    }),

  ...(yourOtherIncome(formData).length && {
    "Your other income": yourOtherIncome(formData),
  }),

  "Your monthly expenses": yourMonthlyExpenses(formData),

  ...(formData.hasDebts === "yes" && {
    "Your debts": yourDebts(formData),
  }),
  "Declaration of changes": declarationOfChanges(formData),
});
