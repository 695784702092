import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../application";
import {
  emptyCurrencyValues,
  paymentFrequencyOptions,
  validPhoneNumberRegex,
} from "../../../application-form.constants";
import { HelpCallout } from "../../help-callout/help-callout";
import { useIsEditing } from "../../hooks/use-is-editing";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import {
  getIsPurposeSecurableByKey,
  sendAppFormViewedEvent,
} from "../../multi-page-application-form.utils";
import {
  livingSituationOptions,
  rentalAgreementStatusMessage,
} from "./living-situation.constants";
import startApplicationStyle from "./living-situation.scss";
import {
  getContactLabels,
  getRentSharedValue,
  isLivingSituationButtonDisabled,
  setRentShared,
} from "./living-situation.utils";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";
import { useGetLoanParameters } from "../../../../loans/parameters/loan-parameters.hooks";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { isValidLengthWithoutDecimals } from "../../../../redux/loan-application/loan-application.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
}

export const LivingSituation: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const loanParameters = useGetLoanParameters();
  const [infoModal, setInfoModal] = React.useState(false);
  const [situationChanged, setSituationChanged] = React.useState(false);
  const [rentSharedChanged, setRentSharedChanged] = React.useState(
    !emptyCurrencyValues.includes(formData.totalRentalAmount) &&
      !!formData.rentalAmountFrequency
  );
  const isRenter = formData.livingSituationAtAddress === "renter";
  const isContactRequired = ["boarder", "living-with-parents", "employer"].some(
    (v) => v === formData.livingSituationAtAddress
  );
  const hasMortgage = formData.livingSituationAtAddress === "buyer";
  const isSecured = getIsPurposeSecurableByKey(
    formData.loanPurpose,
    loanParameters.loanPurpose
  );
  const contactLabels = getContactLabels(formData.livingSituationAtAddress);
  const modalHeading = "Why do we ask this?";
  const modalDescription = `Wisr may contact your ${contactLabels.value} to confirm your living situation.`;
  const isDisabled = isLivingSituationButtonDisabled(
    formData,
    formState,
    rentSharedChanged
  );
  const isEditing = useIsEditing(location);

  const validateContactNumber = (value: string) => {
    return (
      !value ||
      validPhoneNumberRegex.test(value) ||
      "Phone number must be 10 digits, including the area code"
    );
  };

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      if ((isRenter || isContactRequired) && isSecured && situationChanged) {
        navigate("/application/investment");
      } else {
        navigate("/application/employment-and-income");
      }
    }
  };

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.LIVING);
    if (emptyCurrencyValues.includes(formData.totalRentalAmount)) {
      setValue("totalRentalAmount", "");
      setValue("rentalAmountFrequency", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <style jsx>{startApplicationStyle}</style>

      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">
            We need more details about your current living situation
          </h2>
        </div>
        <div className="input-wrapper">
          <Field
            type="select"
            label="Current living situation"
            options={livingSituationOptions}
            {...fieldAdapter({
              name: "livingSituationAtAddress",
              required: "Current living situation is required",
              onChange: () => {
                setSituationChanged(true);
              },
            })}
          />
          <div className="field-pair">
            <Field
              type="currency"
              label="Total rent"
              layout="pair"
              hasCommas={true}
              {...fieldAdapter({
                name: "totalRentalAmount",
                required: "Total rental amount is required",
                min: [1, "Total rental amount must be greater than 0"],
                validate: (value) =>
                  isValidLengthWithoutDecimals(value, 10) ||
                  "Total rental amount cannot be more than 10 digits (including 2 decimals)",
                condition: isRenter,
              })}
            />
            <Field
              type="select"
              label="Frequency"
              layout="pair"
              options={paymentFrequencyOptions}
              placeholder="Select..."
              {...fieldAdapter({
                name: "rentalAmountFrequency",
                required: "Rental amount frequency is required",
                condition: isRenter,
              })}
            />
          </div>
          {isRenter && (
            <Field
              type="radio-button"
              label="Is your rent shared with other people?"
              options={["Yes", "No"]}
              value={
                rentSharedChanged ? getRentSharedValue(formData.rentShared) : ""
              }
              onChange={(e) => {
                setRentShared(e.currentTarget.value, setValue);
                setRentSharedChanged(true);
              }}
            />
          )}
          <Field
            type="input"
            label={contactLabels.name}
            autoComplete="section-property-manager name"
            {...fieldAdapter({
              name: "propertyOwnerManagerName",
              required: "Property owner/manager name is required",
              condition: isContactRequired,
            })}
          />
          <Field
            type="mobile"
            label={contactLabels.number}
            autoComplete="section-property-manager tel-national"
            {...fieldAdapter({
              name: "propertyOwnerManagerPhone",
              required: "Property owner/manager phone number is required",
              condition: isContactRequired,
              validate: validateContactNumber,
            })}
          />
          <Field
            type="input"
            label="Mortgage finance company name"
            {...fieldAdapter({
              name: "mortgageFinanceCompany",
              required: "Mortgage finance company name is required",
              condition: hasMortgage,
            })}
          />
          <Field
            type="currency"
            label="Mortgage balance"
            hasCommas={true}
            {...fieldAdapter({
              name: "mortgageBalance",
              required: "Mortgage balance is required",
              min: [1, "Mortgage balance must be greater than 0"],
              validate: (value) =>
                isValidLengthWithoutDecimals(value, 10) ||
                "Mortgage balance cannot be more than 10 digits (including 2 decimals)",
              condition: hasMortgage,
            })}
          />
          <div className="field-pair">
            <Field
              layout="pair"
              type="currency"
              label="Repayment amount"
              hasCommas={true}
              {...fieldAdapter({
                name: "mortgageRepaymentAmount",
                required: "Mortgage repayment is required",
                min: [1, "Mortgage repayment must be greater than 0"],
                validate: (value) =>
                  isValidLengthWithoutDecimals(value, 10) ||
                  "Mortgage repayment cannot be more than 10 digits (including 2 decimals)",
                condition: hasMortgage,
              })}
            />
            <Field
              layout="pair"
              type="select"
              label="Frequency"
              options={paymentFrequencyOptions}
              placeholder="Select..."
              {...fieldAdapter({
                name: "mortgageRepaymentFrequency",
                required: "Mortgage repayment frequency is required",
                condition: hasMortgage,
              })}
            />
          </div>
          <Field
            type="radio-button"
            label="Do you have a co-borrower on this mortgage?"
            options={["Yes", "No"]}
            placeholder="Select..."
            {...fieldAdapter({
              name: "hasCoBorrower",
              required:
                "Do you have a co-borrower on this mortgage is required",
              condition: hasMortgage,
            })}
          ></Field>
        </div>
        {isRenter && (
          <div className="status-callout-wrapper">
            <StatusCallout
              type="coaching"
              theme="outline"
              message={rentalAgreementStatusMessage}
            />
          </div>
        )}
        {isContactRequired && (
          <HelpCallout text={modalHeading} setInfoModal={setInfoModal} />
        )}
      </div>
      <div className="button-wrapper">
        <Button
          button="primary"
          theme="navy"
          size="large"
          fullWidth={true}
          disabled={isDisabled}
        >
          <button
            className="next-page-button"
            onClick={() => nextPage()}
            disabled={isDisabled}
          >
            {isEditing ? "back to review" : "next"}
          </button>
        </Button>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={modalHeading}
        description={modalDescription}
      />
    </div>
  );
};
